<script>
import TableauCommun from '@/components/shared/tableauCommun'
import PageHeader from '@/components/shared/pageHeader'
import pageActions from '@/components/shared/pageActions'

export default {
  name: 'Enseignant',
  components: {
    pageActions,
    TableauCommun,
    PageHeader
  },
  data () {
    return {
      actions: [
        { title: this.$t('action.add-account'), click: () => { this.$store.commit('App/setGlobalDialog', { value: true, context: 'sansRelation' }) } }
      ]
    }
  },
  computed: {
    enseignants () {
      return this.$store.getters['Compte/enseignants']
    }
  }
}
</script>

<template lang="pug">
.enseignant
  page-header(:title="$t('menu.mes-enseignants')", :backButtonLabel="$t('menu.home')", :backButtonTo="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")

  page-actions(:actions="actions")

  .content.mt3
    section.fullsize-section.animated.fadeIn
      tableau-commun.w-100(
        :nbTotal='enseignants.length',
        :items='enseignants',
        :headers='[{ text: $t("partage.prenom-maj"), value: "info.prenom" }, { text: $t("partage.nom-maj"), value: "info.nom" }, { text: $t("partage.username"), value: "info.username" }, { text: "", value: "actions", sortable: false }]',
        :routeName="'enseignantDetail'"
        :hideFooter="true"
      )
</template>
<style>
</style>
